import { LOG_STUFF } from 'utils/config';
import {
  getJSONWithToken,
  patchJSONWithToken,
  postJSONWithToken,
} from 'utils/utils';
import { useCasesSchema } from './UseCaseSchema';

export const fetchUseCases = (token, projectId) =>
  getJSONWithToken(
    `project/${projectId}/use-case`,
    token,
    'Could not fetch use cases.',
    useCasesSchema
  );

export const modifyUseCase = (token, projectId, useCaseId, payload) =>
  patchJSONWithToken(
    `project/${projectId}/use-case/${useCaseId}`,
    token,
    payload,
    'Could not modify use case.'
  );

export const createUseCase = (token, projectId, payload) =>
  postJSONWithToken(
    `project/${projectId}/use-case`,
    token,
    payload,
    'Could not create use case.'
  );

export const fetchUseCase = (token, projectId, useCaseId) =>
  getJSONWithToken(
    `project/${projectId}/use-case/${useCaseId}`,
    token,
    'Could not fetch use case.'
  );

export const fetchUseCaseItem = async (
  token,
  projectId,
  useCaseId,
  item,
  customError,
  schema
) => {
  const useCase = await fetchUseCase(token, projectId, useCaseId);
  const error = useCase?.errors?.[item];
  if (error) {
    throw new Error(error.message || error.reason || customError);
  }
  try {
    return await schema.validate(useCase[item]);
  } catch (error) {
    if (LOG_STUFF) {
      console.log(error);
    }
    throw new Error(customError);
  }
};
