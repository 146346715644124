import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonLink } from '@bimspot/bimspot-component-library';

import { Mixpanel } from 'utils/MixpanelUtils/MixpanelActions';
import { ReactComponent as AwardRibbonIcon } from 'assets/icons/award-ribbon-star.svg';
import { ReactComponent as ACLBlock } from 'assets/images/acl-block.svg';

const UpgradeButtonComponent = ({ orgs, userId, projectOwnerId, pageName }) => {
  const canUpgrade = !!orgs[projectOwnerId]?.userGroups.admin?.includes(userId);

  return (
    canUpgrade && (
      <ButtonLink
        as={Link}
        to={`/organisation/${projectOwnerId}/upgrade`}
        onClick={() =>
          Mixpanel.trackWithTime('Upgrade plan click', {
            clicked_on: pageName,
          })
        }
      >
        Upgrade now
      </ButtonLink>
    )
  );
};

const mapStateToProps = ({
  orgs,
  userDetails,
  projectDetails: {
    response: { owner },
  },
}) => ({
  orgs: orgs.response,
  userId: userDetails.response.id,
  projectOwnerId: owner,
});

export const UpgradeButton = connect(mapStateToProps)(UpgradeButtonComponent);

const FullPageBlock = ({
  title = 'You don’t have access to this feature',
  description = 'Upgrade to have full access or contact the project’s owner',
  illustration = <ACLBlock />,
  pageName = 'Page block screen',
}) => (
  <div className="full-page-block padded-container bmspt-justify-center bmspt-items-center">
    <div className="bmspt-max-w-2xl bmspt-mx-auto">
      <div className="bmspt-mb-6">{illustration}</div>
      <div className="bmspt-text-center">
        <p className="bmspt-text-2xl bmspt-font-bold bmspt-mb-2">
          <AwardRibbonIcon className="custom-icon custom-icon--primary text-aligned-icon" />
          <span>{title}</span>
        </p>
        <p className="bmspt-mb-4 bmspt-text-xs">{description}</p>
        <UpgradeButton pageName={pageName} />
      </div>
    </div>
  </div>
);

export default FullPageBlock;
