import React from 'react';
import { connect } from 'react-redux';

import { showModal } from 'redux/actions/creators';
import FeatureBlockModal from './FeatureBlockModal';
import FullPageBlock from './FullPageBlock';
import { useProjectACL } from './permissionHooks';

const ProjectPermissionAwareWrapper = ({
  feature,
  children,
  replaceWith = <FullPageBlock />,
  modal = FeatureBlockModal,
  noContentBlock = false,
  showModal,
}) => {
  const block = e => {
    e.stopPropagation();
    e.preventDefault();
    showModal({
      type: modal,
      modalClass: 'custom-modals centered-modal',
    });
  };

  switch (useProjectACL(feature)) {
    case 'available':
      return <>{children}</>;
    case 'content-block':
      return noContentBlock ? <>{children}</> : replaceWith;
    case 'modal':
      return (
        <div
          className="bmspt-contents"
          onClickCapture={block}
          onKeyDownCapture={block}
        >
          {children}
        </div>
      );
    default:
      return null;
  }
};

const mapDispatchToProps = { showModal };

export default connect(
  null,
  mapDispatchToProps
)(ProjectPermissionAwareWrapper);
