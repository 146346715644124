import * as L from 'partial.lenses';
import { handleActions } from 'redux-actions';
import {
  UPLOAD_PENDING,
  PROGRESS_VIEW_MINIMISED,
  PROGRESS_VIEW_DETAILED,
  PROGRESS_VIEW_DISMISSED,
} from '../actions/constants';
import {
  newUpload,
  setUploadReqstate,
  clearUploads,
  setUploadProgressView,
  switchUploadProgressView,
  setUploadProgress,
} from '../actions/creators';

// Custom lenses
const upload = uploadId => [L.elems, L.when(e => e.uploadId === uploadId)];

// Reducers
export const uploads = handleActions(
  {
    [newUpload]: (state, { payload }) => [
      {
        ...payload,
        startedAt: new Date(),
        reqState: UPLOAD_PENDING,
        uploadProgress: {
          loaded: 0,
          total: payload.fileObject.size,
        },
      },
      ...state,
    ],
    [setUploadReqstate]: (state, { payload: { uploadId, reqState } }) =>
      L.assign(upload(uploadId), { reqState }, state),
    [setUploadProgress]: (state, { payload: { uploadId, uploadProgress } }) =>
      L.assign(upload(uploadId), { uploadProgress }, state),
    [clearUploads]: state => {
      state.forEach(({ cancelTokenSource, reqState }) => {
        if (reqState === UPLOAD_PENDING) {
          cancelTokenSource.cancel();
        }
      });
      return [];
    },
  },
  []
);

export const uploadProgressView = handleActions(
  {
    [setUploadProgressView]: (_, { payload }) => payload,
    [newUpload]: () => PROGRESS_VIEW_DETAILED,
    [switchUploadProgressView]: state =>
      state === PROGRESS_VIEW_MINIMISED
        ? PROGRESS_VIEW_DETAILED
        : PROGRESS_VIEW_MINIMISED,
    [clearUploads]: () => PROGRESS_VIEW_DISMISSED,
  },
  PROGRESS_VIEW_DISMISSED
);
