import { Button } from '@bimspot/bimspot-component-library';
import { XIcon } from '@heroicons/react/outline';
import classnames from 'classnames';

const ModalCloseButton = ({ transparent, ...props }) => (
  <Button
    className={classnames(
      'bmspt-absolute bmspt-right-0 bmspt-mt-4 bmspt-mr-4 bmspt-px-0 bmspt-py-0 bmspt-shadow-none',
      {
        'bmspt-w-5 bmspt-h-5 bmspt-rounded-full': !transparent,
        'bmspt-w-8 bmspt-h-8 bmspt-border-0 bmspt-z-1': transparent,
      }
    )}
    variant={transparent ? 'outline' : 'secondary'}
    aria-label="close"
    {...props}
  >
    <XIcon
      className={classnames('bmspt-text-black bmspt-text-opacity-50', {
        'bmspt-w-4 bmspt-h-4': !transparent,
        'bmspt-w-7 bmspt-h-7': transparent,
      })}
      aria-hidden="true"
    />
  </Button>
);

export default ModalCloseButton;
