import { useState, useRef, useEffect, useCallback } from 'react';
import allSettled from 'promise.allsettled';
import { useHistory } from 'react-router-dom';

// Possible states that our async request can be at any given time
export const INITIAL = 'INITIAL';
export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';

export const isError = state =>
  state !== INITIAL && state !== FULFILLED && state !== PENDING;
export const isSuccess = state => state === FULFILLED;
export const isPending = state => state === PENDING;

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

export const useAsyncCallback = (
  promiseFn,
  initialData,
  minTime = 0,
  shouldRedirectToLogin = true,
  shouldUseInitialDataOnPending = false
) => {
  // the whole state is kept together to minimise rerenders
  const [state, setState] = useState({
    result: initialData,
    state: INITIAL,
  });

  // lófasz
  const history = useHistory();
  // console.log(history);

  // prevent race conditions
  const reqCounter = useRef(0);
  const initialDataRef = useRef(initialData);

  // when the component unmounts, set reqCounter back to 0
  useEffect(() => () => (reqCounter.current = 0), []);

  const callback = useCallback(
    async (...args) => {
      const localCounterNum = ++reqCounter.current;

      setState(
        shouldUseInitialDataOnPending
          ? { result: initialDataRef.current, state: PENDING }
          : currentState => ({
              ...currentState,
              state: PENDING,
            })
      );

      const [{ status, value: result, reason }] = await allSettled([
        promiseFn(...args),
        timeout(minTime),
      ]);

      if (reqCounter.current === localCounterNum) {
        if (status === 'fulfilled') {
          setState({ result, state: FULFILLED });
        } else if (
          reason.code === 401 &&
          reason.message.includes('jwt') &&
          shouldRedirectToLogin
        ) {
          history.push({
            pathname: '/logout',
            state: {
              from: history.location.pathname,
            },
          });
        } else {
          setState(currentState => ({ ...currentState, state: reason }));
        }
      }
    },
    [
      promiseFn,
      minTime,
      history,
      shouldRedirectToLogin,
      shouldUseInitialDataOnPending,
    ]
  );

  return [callback, state.state, state.result];
};

const isAllSettled = states =>
  states.every(state => state !== INITIAL && state !== PENDING);

const combineSettled = states => {
  const errors = states.filter(isError);

  const message = errors.map(({ message }) => message).join('\n');

  const isJWTExpired = errors.some(
    ({ code, message }) => code === 401 && message.includes('jwt')
  );

  return message ? { message, isJWTExpired } : FULFILLED;
};

export const combineReqStates = states =>
  isAllSettled(states)
    ? combineSettled(states)
    : states.some(isPending)
    ? PENDING
    : INITIAL;
