import * as yup from 'yup';

export const userSchema = yup
  .object()
  .shape({
    createdAt: yup.date().required(),
    updatedAt: yup.date().required(),
    email: yup
      .string()
      .email()
      .required(),
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
    role: yup.string().nullable(),
    id: yup.string().required(),
    phoneNumber: yup.string().nullable(),
    activeInBimProject: yup.boolean(),
    notificationPreferences: yup.object().shape({
      marketing: yup
        .array()
        .of(yup.string())
        .default(['none']),
      product: yup
        .array()
        .of(yup.string())
        .default(['none']),
      activity: yup
        .array()
        .of(yup.string())
        .default(['none']),
      modelCheck: yup
        .array()
        .of(yup.string())
        .default(['none']),
    }),
  })
  .noUnknown(true);
