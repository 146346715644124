export const UPLOAD_PENDING = 'UPLOAD_PENDING';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_CANCELLED = 'UPLOAD_CANCELLED';

export const PROGRESS_VIEW_DISMISSED = 'PROGRESS_VIEW_DISMISSED';
export const PROGRESS_VIEW_MINIMISED = 'PROGRESS_VIEW_MINIMISED';
export const PROGRESS_VIEW_DETAILED = 'PROGRESS_VIEW_DETAILED';

export const modalClosed = { show: false };
