import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../config';

let distinct_id;

mixpanel.init(MIXPANEL_TOKEN, {
  loaded: function(mixpanel) {
    distinct_id = mixpanel.get_distinct_id();
  },
});

const track = fn => (...args) => {
  const { name, props = {}, disabled } = fn(...args);
  if (!disabled) {
    mixpanel.track(name, { $time: new Date().toISOString(), ...props });
  }
};

const trackClick = name =>
  track(clicked_on => ({
    name,
    props: { clicked_on },
  }));

export const Mixpanel = {
  alias: id => {
    mixpanel.alias(id, distinct_id);
  },
  identify: id => {
    mixpanel.identify(id);
  },
  logOut: () => {
    mixpanel.track('User log out');
    mixpanel.reset();
  },
  register: email => {
    // prettier-ignore
    mixpanel.register({ "Email": email });
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  trackWithTime: (name, props = {}) => {
    mixpanel.track(name, { $time: new Date().toISOString(), ...props });
  },
  people: {
    set: props => {
      mixpanel.people.set(props);
    },
    set_once: (props, value) => {
      mixpanel.people.set_once(props, value);
    },
    increment: props => {
      mixpanel.people.increment(props);
    },
  },
  get_distinct_id: () => {
    return distinct_id;
  },
  uploadModalCancelStep: trackClick('Upload modal: Cancel button click'),

  textInputOnFocus: track((field_location, text_input) => ({
    name: 'Text input typing start',
    props: { field_location, text_input },
  })),

  textInputOnBlur: track((field_location, text_input) => ({
    name: 'Text input typing finish',
    props: { field_location, text_input },
  })),

  uploadModalDismiss: trackClick('Upload modal dismiss'),

  addBuildingSubmitBtnClick: trackClick('Add building submit button click'),

  uploadModalAddBuildingPartBuildingSelection: trackClick(
    'Upload modal: Select building in add building part'
  ),

  uploadModalAddBuildingPartSubmitBtnClick: trackClick(
    'Add building part submit button click'
  ),

  addBuildingPartBtnClick: trackClick('Add building part button click'),

  uploadModalBuildingPartSelection: trackClick(
    'Upload modal: Select of a building part'
  ),

  uploadModelSubmitBtn: trackClick('Model upload submit button click'),

  uploadModelStart: track(() => ({
    name: 'Model upload start',
  })),

  uploadModelFinish: track(status => ({
    name: 'Model upload finish',
    props: { status },
  })),

  uploadProgressPanelToggle: track((boolean, status) => ({
    name: `Upload progress: panel ${boolean ? 'maximized' : 'minimized'}`,
    props: { upload_status: status ? 'upload in progress' : 'uploaded' },
  })),

  uploadProgressPanelClose: track(status => ({
    name: 'Upload progress: panel close',
    props: {
      upload_status: status ? 'upload in progress' : 'uploaded',
    },
  })),

  uploadProgressPanelAlert: track((status, confirmed) => ({
    name: 'Upload progress: panel alert on close attempt',
    props: {
      confirmed,
      user_action: confirmed
        ? 'User caceled the model upload'
        : 'User continued the model upload',
    },
    disabled: !status,
  })),

  uploadProgressPanelCancelUpload: track(() => ({
    name: 'Upload progress: panel cancel upload',
  })),

  addBuildingBtnClick: trackClick('Add building button click'),

  menuIconClick: track(item => ({
    name: `Icon ${item} click`,
    props: {
      clicked_on: 'Sidebar Menu',
    },
  })),

  requestErrors: track((endpoint, message, code) => ({
    name: 'Request error',
    props: { endpoint, message, 'error code': code },
  })),

  modelChecksSelection: track(type => ({
    name: `${type} selection`,
  })),

  modelChecksFinish: track(type => ({
    name: `${type} finish`,
  })),

  modelChecksReportDownload: track(type => ({
    name: `${type} download report`,
  })),

  filterPanelToggle: track((open, location) => ({
    name: `Filter panel ${open ? 'opened' : 'closed'}`,
    props: { location },
  })),

  filterPanelMultiSelections: track((type, value, allValues) => ({
    name: `Filter panel - ${type} selection`,
    props: {
      'Selected Value': value,
      'Selected Values (before new selection)': allValues,
    },
  })),

  filterPanelSelections: track((type, value) => ({
    name: `Filter panel - ${type} selction`,
    props: { 'Selected Value': value },
  })),

  filterPanelCheckbox: track((type, value) => ({
    name: `Filter panel - ${type} click`,
    props: { 'Selected Value': value },
  })),

  filterPanelActions: track(type => ({
    name: `Filter panel - ${type} click`,
  })),

  filterPanelActionsSavedFilters: track(isOwner => ({
    name: 'Filter panel - Saved-filter-item click',
    props: { isOwner },
  })),

  kpiAction: track(type => ({
    name: `KPI ${type} click`,
  })),

  kpiActionWithValue: track((type, value) => ({
    name: `KPI - ${type} click`,
    props: { 'Selected Value': value },
  })),

  qtoAction: track(type => ({
    name: `QTO - ${type} click`,
  })),

  qtoActionWithValue: track((type, value) => ({
    name: `QTO - ${type} click`,
    props: {
      'Selected Value': value,
    },
  })),

  reqFulAction: track(type => ({
    name: `Requirement Fulfilment - ${type} click`,
  })),

  infoReqAction: track(type => ({
    name: `Information Requirements - ${type} click`,
  })),

  modelManagementAction: track(type => ({
    name: `Model management - ${type} click`,
  })),

  deviceAuthConnect: track(type => ({
    name: `Device Auth - Connect - ${type}`,
  })),

  deviceAuthActivate: track(type => ({
    name: `Device Auth - Activate - ${type}`,
  })),
};
