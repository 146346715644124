import { call, put, select, take } from 'redux-saga/effects';
import { setOrg } from 'redux/actions/creators';
import * as actions from 'redux/actions/saga';
import { createNewUser } from 'utils/AuthUtils/AuthUtils';
import { updateOrg } from 'utils/OrganisationUtils/OrganisationUtils';
import { INITIAL, PENDING } from 'utils/useAsyncCallback';
import { filterEmptyValuesFromObject } from 'utils/utils';
import { Mixpanel } from 'utils/MixpanelUtils/MixpanelActions';

// Signup steps
export const SIGNUP = 'SIGNUP';
export const COMPANY_INFO = 'COMPANY_INFO';
export const CREATE_PROJECT = 'CREATE_PROJECT';

function* signupStep(pattern, saga, ...args) {
  while (true) {
    const action = yield take(pattern);
    yield put(actions.modifySignupState({ status: PENDING }));
    try {
      return yield call(saga, ...args, action);
    } catch ({ message, code }) {
      yield put(actions.modifySignupState({ status: { message, code } }));
    }
  }
}

function* signup({
  payload: {
    search,
    signupData: {
      firstname,
      lastname,
      email,
      phoneNumber,
      password,
      activeInBimProject,
      updates,
      terms,
    },
  },
}) {
  const queryParams = new URLSearchParams(search);
  const invitationCode = queryParams.get('i');

  // signup

  const notification = updates ? ['email'] : ['none'];

  const notificationPreferences = {
    marketing: notification,
    product: notification,
    activity: notification,
    modelCheck: notification,
  };

  const credentials = { email, password };

  const mixpanelDistinctId = Mixpanel.get_distinct_id();

  const withData = {
    ...credentials,
    firstname,
    lastname,
    phoneNumber,
    terms,
    notificationPreferences,
    mixpanelDistinctId,
    activeInBimProject,
  };
  const signupData = invitationCode
    ? { ...withData, invitationCode }
    : withData;

  const date = new Date();

  Mixpanel.track('User signup start', {
    $time: date.toISOString(),
    signupEmail: email,
  });

  // Attempt to create the new user
  const user = yield call(createNewUser, search, signupData);

  window.dataLayer.push({ event: 'successfulSignup' });

  Mixpanel.alias(user.id);
  Mixpanel.people.set({
    $email: email,
    $created: date.toISOString(),
    bimspotUserId: user.id,
    createdThrough: 'self-created',
    signupSource: 'bimspot.io',
    phoneNumber: phoneNumber,
    firstName: firstname,
    lastName: lastname,
    activeInBimProject: activeInBimProject,
  });

  Mixpanel.trackWithTime('User signup finish');

  // login
  yield put(actions.loginWithCredentials({ email, password }));

  const loginResult = yield take([actions.loginSuccess, actions.loginError]);

  if (loginResult.type === actions.loginSuccess.toString()) {
    yield put(
      actions.modifySignupState({ status: INITIAL, currentStep: COMPANY_INFO })
    );

    // return the organisationId
    return user.organizationId;
  } else {
    throw loginResult.payload;
  }
}

function* updateCompanyInformation(orgId, { payload }) {
  const token = yield select(({ authState: { token } }) => token);

  yield call(updateOrg, token, orgId, filterEmptyValuesFromObject(payload));

  Mixpanel.trackWithTime('Organisation information submitted');

  yield put(setOrg(orgId, payload));

  yield put(
    actions.modifySignupState({ status: INITIAL, currentStep: CREATE_PROJECT })
  );
}

export function* signupFlow() {
  while (true) {
    const orgId = yield* signupStep(actions.createAccount, signup);
    yield* signupStep(
      actions.updateCompanyInformation,
      updateCompanyInformation,
      orgId
    );
  }
}
