import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import {
  fetchAndSaveProjectDetails,
  fetchAndSaveProjectList,
} from 'redux/sagas/project';
import jwtDecode from 'jwt-decode';
import { LOCATION_CHANGE } from 'redux-first-history';
import { Mixpanel } from 'utils/MixpanelUtils/MixpanelActions';
import {
  setSelectedProjectId,
  fetchProjectList,
  showModal,
  setProjectList,
  setActiveProject,
  setProjectDetails,
  setUseCases,
  setActiveUseCase,
} from 'redux/actions/creators';
import { fetchAndSavePermissions } from 'redux/sagas/permissions';
import { fetchAndSaveACLStuff } from 'redux/sagas/acl';
import { fetchAndSaveOrgList } from 'redux/sagas/org';
import { fetchAndSaveUserDetails } from 'redux/sagas/user';
import * as projectUtils from 'utils/ProjectUtils/ProjectUtils';
import ProjectNotFoundModal from 'Projects/ProjectNotFoundModal';
import { FULFILLED, isSuccess } from 'utils/useAsyncCallback';
import { history } from 'redux/store';
import { modalClosed } from 'redux/actions/constants';
import { fetchAndSaveUseCases } from '../useCases';

function* watchLocationChange({ payload }) {
  const {
    modals: [{ show }],
    selectedProjectId,
  } = yield select();

  // Close open modals on location change
  if (show) {
    yield put(showModal(modalClosed));
  }

  // Set selected project id based on url
  const projectId = matchPath(payload.location.pathname, {
    path: '/project/:id/:route',
  })?.params.id;

  if (projectId && projectId !== selectedProjectId) {
    yield put(setSelectedProjectId(projectId));
  }
}

function* findUseCase() {
  const { useCases } = yield select();

  if (!isSuccess(useCases.status)) {
    return;
  }

  const useCaseId =
    useCases.activeUseCase?.id || localStorage.getItem('selectedUseCaseId');

  const activeUseCase = useCases.response.find(({ id }) => id === useCaseId);

  if (activeUseCase && activeUseCase.id !== useCases.activeUseCase?.id) {
    return yield put(setActiveUseCase(activeUseCase));
  }

  const firstUseCase = useCases.response[0];

  if (!activeUseCase && firstUseCase) {
    return yield put(setActiveUseCase(firstUseCase));
  }

  if (useCases.activeUseCase && !firstUseCase) {
    return yield put(setActiveUseCase(null));
  }
}

function* findProject({ type, payload }) {
  const { projectList, selectedProjectId } = yield select();

  if (!isSuccess(projectList.status) || !selectedProjectId) {
    return;
  }

  if (type === setProjectDetails.toString()) {
    // If the selected project's name was changed, modify it in the project list as well
    if (
      isSuccess(payload.status) &&
      projectList.activeProject?.name !== payload.response.name
    ) {
      yield put(
        setProjectList(
          FULFILLED,
          projectList.response.map(project =>
            project.id === payload.response.id
              ? { ...project, name: payload.response.name }
              : project
          )
        )
      );
    }
    return;
  }

  const activeProject = projectList.response.find(
    project => project.id === selectedProjectId
  );

  if (activeProject) {
    yield put(setActiveProject(activeProject));
  } else {
    yield all([
      put(setActiveProject(null)),
      put(
        showModal({
          type: ProjectNotFoundModal,
          modalClass: 'bmspt-p-12 project-not-found-modal',
          onHide: () => {
            const id = projectList.response[0]?.id;
            history.push(id ? `/project/${id}/project-cockpit` : '/no-project');
          },
        })
      ),
    ]);
  }
}

export function* watchProjectIdChange() {
  yield all([call(saveSelectedProjectId), call(fetchAndSaveProjectDetails)]);
}

export function* fetchInitialData() {
  const token = yield select(({ authState: { token } }) => token);
  const {
    data: { userId },
  } = jwtDecode(token);

  Mixpanel.identify(userId);
  Mixpanel.trackWithTime('User log in');
  Mixpanel.people.set_once('N. of Projects', 0);

  yield all([
    call(saveToken, token),
    takeLatest(LOCATION_CHANGE, watchLocationChange),
    takeLatest(setSelectedProjectId, watchProjectIdChange),
    takeLatest(
      [setSelectedProjectId, setProjectList, setProjectDetails],
      findProject
    ),
    call(getProjectId, token),
    call(fetchAndSavePermissions, token),
    call(fetchAndSaveACLStuff, token),
    call(fetchAndSaveOrgList, token),
    call(fetchAndSaveUserDetails, token, userId),
    takeLeading(fetchProjectList, fetchAndSaveProjectList),
    takeLatest(setActiveUseCase, saveSelectedUseCaseId),
    takeLatest(setUseCases, findUseCase),
    takeLatest(setSelectedProjectId, fetchAndSaveUseCases),
    put(fetchProjectList()),
  ]);
}

export function* getProjectId(token) {
  // Read the projectId from Redux, use that if exists
  const { selectedProjectId } = yield select();
  if (selectedProjectId) {
    return yield put(setSelectedProjectId(selectedProjectId));
  }
  // Read the projectId from localStorage, use that if exists
  const projectId = localStorage.getItem('selectedProjectId');
  if (projectId) {
    return yield put(setSelectedProjectId(projectId));
  }

  // No projectId found, select the first one from project list
  try {
    const projects = yield call(projectUtils.fetchProjectList, token);
    if (projects[0]) {
      yield put(setSelectedProjectId(projects[0].id));
    }
  } catch {}
}

export function* saveSelectedProjectId() {
  try {
    const { selectedProjectId } = yield select();
    localStorage.setItem('selectedProjectId', selectedProjectId);
  } catch {}
}

export function saveToken(token) {
  try {
    localStorage.setItem('authToken', token);
  } catch {}
}

export function* saveSelectedUseCaseId() {
  try {
    const { useCases } = yield select();
    localStorage.setItem('selectedUseCaseId', useCases.activeUseCase?.id ?? '');
  } catch {}
}
