import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  NoMatch,
  renderBasedOnReqState,
  LoadingBimspotLogo,
} from './renderUtils';
import { INITIAL, combineReqStates } from './useAsyncCallback';

// PrivateRoute utility
// We probably want most routes to be private, that is, not accessible
// unless the user is actually authenticated
// In order to achieve this, we wrap the Route component in
// a Higher Order Component
// This HOC does the following:
// If the user is authenticated, it renders the component passed to the Route
// Otherwise, it redirects the user to the login page
const Protected = ({
  component: Component,
  status,
  permissionLevel,
  authorisedGroups,
  hasNoProject,
  allowedWithoutProject,
  accessIsBlocked,
  ...rest
}) => {
  const selectScreenPerPermission = props => {
    if (hasNoProject && !allowedWithoutProject) {
      return <Redirect to="/no-project" />;
    }

    if (accessIsBlocked) {
      return (
        <NoMatch
          description="Oops, there doesn't seem to be anything here. Let us help you find your
      way. Click the button below to take you where you need to go."
          ctaText="Go to bimspot cloud platform"
          {...props}
        />
      );
    }

    if (!authorisedGroups || authorisedGroups.includes(permissionLevel)) {
      return <Component {...rest} {...props} />;
    }

    return (
      <div className="padded-container" {...props}>
        You're not authorised to access this resource.
      </div>
    );
  };

  return (
    <Route
      {...rest}
      render={props =>
        status === INITIAL ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ) : (
          renderBasedOnReqState(
            status,
            <LoadingBimspotLogo />,
            selectScreenPerPermission(props),
            status.isJWTExpired ? (
              <Redirect to="/login" />
            ) : (
              status.message || 'Unknown error'
            )
          )
        )
      }
    />
  );
};

const mapStateToProps = (
  { permissions, newPermissions, selectedProjectId },
  { feature }
) => {
  const projectACL = newPermissions.response?.projects?.[selectedProjectId];
  const accessIsBlocked = !projectACL
    ? false
    : !!feature &&
      !['available', 'content-block'].includes(
        newPermissions.response?.projects?.[selectedProjectId]?.features[
          feature
        ]
      );

  return {
    status: combineReqStates([permissions.status, newPermissions.status]),
    permissionLevel: permissions.response?.[selectedProjectId],
    hasNoProject: Object.keys(permissions.response || {}).length === 0,
    accessIsBlocked,
  };
};

// for temporary testing purposes... maybe...
// switch dynamic selectedProjectId to static projectId from mock JSON
// or make action + reducer combo that takes projectId and a boolean (has ended)
// ^^2nd option may be preferable

export const PrivateRoute = connect(mapStateToProps)(Protected);
