import { createActions } from 'redux-actions';

// Auth related
export const {
  loginWithCredentials,
  loginWithToken,
  loginError,
  loginSuccess,
  twoFaPrompt,
  twoFaRequest,
  modifyAuthState,
  logoutRequest,
} = createActions({
  LOGIN_WITH_CREDENTIALS: credentials => credentials,
  LOGIN_WITH_TOKEN: token => token,
  LOGIN_ERROR: errorObj => errorObj,
  LOGIN_SUCCESS: token => token,
  TWO_FA_PROMPT: null,
  TWO_FA_REQUEST: verificationCode => verificationCode,
  MODIFY_AUTH_STATE: payload => payload,
  LOGOUT_REQUEST: null,
});

export const { connectDevice, setCode, setTimer } = createActions({
  CONNECT_DEVICE: null,
  SET_TIMER: started => started,
  SET_CODE: code => code,
});

// Signup related
export const {
  modifySignupState,
  createAccount,
  updateCompanyInformation,
} = createActions({
  MODIFY_SIGNUP_STATE: payload => payload,
  CREATE_ACCOUNT: (search, signupData) => ({ search, signupData }),
  UPDATE_COMPANY_INFORMATION: organisation => organisation,
});

export const {
  startPollingWorkflows,
  stopPollingWorkflows,
  setWorkflows,
} = createActions({
  START_POLLING_WORKFLOWS: null,
  SET_WORKFLOWS: workflows => workflows,
});
