import {
  getJSONWithToken,
  postJSONWithToken,
  patchJSONWithToken,
  requestJSON,
} from '../utils';
import {
  projectDetailsSchema,
  projectListSchema,
  buildingRolesSchema,
  createBuildingSchema,
  createBuildingPartSchema,
  idMapsSchema,
} from './ProjectSchemas';

// Project roles
export const roles = {
  Architect: 'ARC',
  'Structural Engineer': 'STR',
  'Mechanical, Electrical, and Plumbing': 'MEP',
};

export const ifcSchemas = {
  IFC2X3: 'IFC2x3',
  IFC4: 'IFC4',
};

export const getIfcSchemaName = version => ifcSchemas[version] || version;

const PROJECT_FETCH_ERROR = 'Could not fetch projects.';
const PROJECT_CREATE_ERROR = 'Could not create project.';
export const PROJECT_FETCH_DETAILS_ERROR =
  'Could not fetch data for the project.';
const PROJECT_UPDATE_ERROR = 'Could not update project.';
const BUILDING_UPDATE_ERROR = 'Could not update building.';
const BUILDING_CREATE_ERROR = 'Could not create building.';
const BUILDING_ROLES_FETCH_ERROR = 'Could not fetch building roles.';
const BUILDING_CREATE_PART_ERROR = 'Could not create building part.';
const BUILDING_UPDATE_PART_ERROR = 'Could not update building part.';
const ID_MAP_FETCH_ERROR = 'Could not fetch id maps.';

// Fetch project list
export const fetchProjectList = token =>
  getJSONWithToken('project', token, PROJECT_FETCH_ERROR, projectListSchema);

// Create project
export const createProject = (token, payload) =>
  postJSONWithToken('project', token, payload, PROJECT_CREATE_ERROR);

// Fetch project details
export const fetchProjectDetails = (token, projectId) =>
  getJSONWithToken(
    `project/${projectId}/management`,
    token,
    PROJECT_FETCH_DETAILS_ERROR,
    projectDetailsSchema
  );

// Update project
export const updateProject = (token, projectId, payload) =>
  patchJSONWithToken(
    `project/${projectId}`,
    token,
    payload,
    PROJECT_UPDATE_ERROR
  );

export const updateBuilding = (token, projectId, buildingId, payload) =>
  patchJSONWithToken(
    `project/${projectId}/building/${buildingId}`,
    token,
    payload,
    BUILDING_UPDATE_ERROR
  );

export const createBuilding = (token, projectId, payload) =>
  postJSONWithToken(
    `project/${projectId}/building`,
    token,
    payload,
    BUILDING_CREATE_ERROR,
    createBuildingSchema
  );

export const fetchBuildingRoles = (token, projectId, buildingId) =>
  getJSONWithToken(
    `project/${projectId}/building/${buildingId}/role`,
    token,
    BUILDING_ROLES_FETCH_ERROR,
    buildingRolesSchema
  );

export const createBuildingPart = (
  token,
  projectId,
  buildingId,
  roleId,
  payload
) =>
  postJSONWithToken(
    `project/${projectId}/building/${buildingId}/role/${roleId}/building-part`,
    token,
    payload,
    BUILDING_CREATE_PART_ERROR,
    createBuildingPartSchema
  );

export const updateBuildingPart = (
  token,
  projectId,
  buildingId,
  roleId,
  buildingPartId,
  payload
) =>
  patchJSONWithToken(
    `project/${projectId}/building/${buildingId}/role/${roleId}/building-part/${buildingPartId}`,
    token,
    payload,
    BUILDING_UPDATE_PART_ERROR
  );

export const fetchIdMaps = (token, projectId) =>
  getJSONWithToken(
    `project/${projectId}/id-map`,
    token,
    ID_MAP_FETCH_ERROR,
    idMapsSchema
  );

export const deleteBuildingPart = (
  token,
  projectId,
  buildingId,
  roleId,
  buildingPartId,
  customError
) =>
  requestJSON(
    `project/${projectId}/building/${buildingId}/role/${roleId}/building-part/${buildingPartId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
    customError
  );
