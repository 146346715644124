import * as L from 'partial.lenses';
import { handleActions } from 'redux-actions';
import {
  loginError,
  loginSuccess,
  twoFaPrompt,
  twoFaRequest,
  modifyAuthState,
  modifySignupState,
  setCode,
  setTimer,
  setWorkflows,
} from 'redux/actions/saga';
import { SIGNUP } from 'redux/sagas/signupFlow';
import { FULFILLED, INITIAL, PENDING } from 'utils/useAsyncCallback';

export const authState = handleActions(
  {
    [modifyAuthState]: (state, { payload }) => L.assign([], payload, state),
    [loginSuccess]: (state, { payload: token }) =>
      L.assign([], { status: FULFILLED, token }, state),
    [loginError]: (state, { payload }) => L.set('status', payload, state),
    [twoFaPrompt]: L.assign([], { status: INITIAL, currentStep: '2FA' }),
    [twoFaRequest]: L.assign([], { status: PENDING, currentStep: '2FA' }),
  },
  {
    token: null,
    status: INITIAL,
    currentStep: 'LOGIN',
  }
);

export const signupState = handleActions(
  {
    [modifySignupState]: (state, { payload }) => L.assign([], payload, state),
  },
  { status: INITIAL, currentStep: SIGNUP }
);

export const activation = handleActions(
  {
    [setCode]: (state, { payload }) => L.set('code', payload, state),
    [setTimer]: (state, { payload }) => L.set('timerStarted', payload, state),
  },
  { code: '', timerStarted: false }
);

export const workflows = handleActions(
  {
    [setWorkflows]: (state, { payload }) => L.assign([], payload, state),
  },
  { status: INITIAL, response: [] }
);
