import { getJSONWithToken, patchJSONWithToken } from '../utils';
import { organisationListSchema, countriesSchema } from './OrganisationSchema';

export const FETCH_ORG_LIST_ERROR = 'Could not fetch organisations.';
export const UPDATE_ORG_ERROR = 'Could not update organisation info.';
const FETCH_COUNTRIES_ERROR = 'Could not fetch countries.';

export const fetchOrgList = token =>
  getJSONWithToken(
    `organization`,
    token,
    FETCH_ORG_LIST_ERROR,
    organisationListSchema
  );

export const updateOrg = (token, orgId, payload) =>
  patchJSONWithToken(`organization/${orgId}`, token, payload, UPDATE_ORG_ERROR);

export const fetchCountries = token =>
  getJSONWithToken(
    `country-list`,
    token,
    FETCH_COUNTRIES_ERROR,
    countriesSchema
  );

export const useCaseMapping = {
  visualisation: 'Visualisation',
  costEstimation: 'Cost Estimation',
  scheduling: 'Scheduling',
  operation: 'Operation',
  coordination: 'Coordination',
};

export const companySizeMapping = {
  '1-10': '1-10',
  '11-50': '11-50',
  '51-200': '51-200',
  '201-500': '201-500',
  'more than 500': 'more than 500',
};
