import { Link } from 'react-router-dom';
import { ButtonLink } from '@bimspot/bimspot-component-library';

import { ReactComponent as ProjectCover } from 'assets/images/empty-states/project.svg';

const NoProject = () => (
  <div className="bmspt-flex bmspt-flex-col bmspt-h-screen bmspt-justify-center bmspt-items-center">
    <ProjectCover className="bmspt-max-w-full bmspt-max-h-full" />
    <h1 className="bmspt-mt-6 bmspt-text-center bmspt-font-bold bmspt-text-2xl">
      No project found
    </h1>
    <ButtonLink
      as={Link}
      className="bmspt-px-4 bmspt-mt-4"
      to="/create-project"
    >
      Create my project
    </ButtonLink>
  </div>
);

export default NoProject;
