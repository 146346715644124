import { setOrgList } from 'redux/actions/creators';
import { fetchOrgList } from 'utils/OrganisationUtils/OrganisationUtils';
import { groupBy, mapObj } from 'utils/utils';
import { fetchAndSaveSagaWrapper } from './utils';

const transformFn = apiResult =>
  mapObj(
    ([item]) => ({
      ...item,
      userGroups: item.userGroups.reduce((accum, currentVal) => {
        accum[currentVal.name] = currentVal.users;
        return accum;
      }, {}),
    }),
    groupBy(({ id }) => id, apiResult)
  );

export const fetchAndSaveOrgList = fetchAndSaveSagaWrapper({
  setter: setOrgList,
  fetchFn: fetchOrgList,
  transformApiResult: transformFn,
});
