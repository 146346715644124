import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Router } from 'react-router-dom';

import { history } from 'redux/store';
import { LoadingBimspotLogo } from 'utils/renderUtils';
import ErrorBoundary from 'utils/ErrorBoundary';

import './scss/custom.scss';
import Routes from 'Routes';
import { Toast } from '@bimspot/bimspot-component-library';

const App = () => (
  <>
    <Helmet>
      <title>
        BIM Models | Building Information Modelling | BIM made easy | bimspot
      </title>
      <meta
        name="description"
        content="Access all your BIM model related information in one place. We visualise BIM data & analyse models and buildings."
      />
    </Helmet>
    <Router history={history}>
      <ErrorBoundary>
        <Suspense fallback={<LoadingBimspotLogo />}>
          <Routes />
        </Suspense>
      </ErrorBoundary>
    </Router>
    <Toast.Region id="toast-region" className="!bmspt-py-16" />
  </>
);

export default App;
