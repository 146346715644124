import { useSelector } from 'react-redux';

export const useIsAuthorised = authorisedGroups => {
  const permissionLevel = useSelector(
    ({ permissions, selectedProjectId }) =>
      permissions.response[selectedProjectId]
  );

  return (
    authorisedGroups.includes(permissionLevel) ||
    authorisedGroups.includes('any')
  );
};

export const useIsMe = userId =>
  userId === useSelector(({ userDetails }) => userDetails.response.id) &&
  !!userId;

export const useProjectACL = feature =>
  useSelector(
    ({ newPermissions, selectedProjectId }) =>
      newPermissions.response.projects?.[selectedProjectId]?.features[feature]
  );
