import * as yup from 'yup';

const useCaseSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    status: yup.string().required(),
    kpiCount: yup.number().required(),
    qtoCount: yup.number().required(),
    updatedAt: yup.date().required(),
  })
  .noUnknown(true);

export const useCasesSchema = yup
  .array()
  .of(useCaseSchema)
  .ensure();

export const useCaseFormSchema = yup
  .object()
  .shape({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
  })
  .noUnknown(true);
