import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// Redux related imports
import { Provider } from 'react-redux';
import { sagaMiddleware, store } from 'redux/store';

// tailwind generated css
import './styles/index.css';
import rootSaga from 'redux/sagas';

// run the saga
sagaMiddleware.run(rootSaga);

// We then pass the store to the Provider component.
// This ensures that any time we connect to Redux in our app
// via react-redux connect, the store is available to our components.
// https://redux.js.org/recipes/configuring-your-store
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
