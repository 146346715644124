import * as yup from 'yup';

const buildingPartsSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string().required(),
    createdAt: yup.date().required(),
    updatedAt: yup.date().required(),
    lastUserActivity: yup
      .object()
      .shape({
        date: yup.date().required(),
        email: yup
          .string()
          .email()
          .ensure(),
      })
      .nullable()
      .default(null),
  })
  .noUnknown(true);

const rolesSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string().required(),
    createdAt: yup.date().required(),
    updatedAt: yup.date().required(),
    buildingParts: yup
      .array()
      .of(buildingPartsSchema)
      .ensure(),
  })
  .noUnknown(true);

const buildingsSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    name: yup.string().required(),
    roles: yup
      .array()
      .of(rolesSchema)
      .required(),
  })
  .noUnknown(true);

const planSchema = yup.object().shape({
  createdAt: yup.date().required(),
  name: yup.string().required(),
});

const projectObjSchema = yup
  .object()
  .shape({
    algoliaIndices: yup
      .array()
      .of(yup.string().required())
      .ensure(),
    id: yup.string().required(),
    buildings: yup
      .array()
      .of(buildingsSchema)
      .ensure(),
    name: yup.string().required(),
    netArea: yup.number(),
    owner: yup.string().required(),
    plans: yup
      .array()
      .of(planSchema)
      .ensure(),
    zeroPoint: yup.object().shape({
      x: yup.number().required(),
      y: yup.number().required(),
      z: yup.number().required(),
    }),
    ifcSchema: yup.string().ensure(),
  })
  .noUnknown(true);

export const projectListSchema = yup
  .array()
  .of(projectObjSchema)
  .required();

export const projectDetailsSchema = projectObjSchema;

export const buildingRolesSchema = yup
  .array()
  .of(rolesSchema)
  .ensure();

export const createBuildingSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
  })
  .noUnknown(true);

export const createBuildingPartSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
  })
  .noUnknown(true);

const idMapSchema = yup
  .object()
  .values(yup.string().required(), { strict: true })
  .required();

export const idMapsSchema = yup
  .object()
  .shape({
    buildings: idMapSchema,
    roles: idMapSchema,
    buildingParts: idMapSchema,
  })
  .noUnknown(true);

const zeroPointRegex = /^((-|\+)?\d+(\.\d+)?),((-|\+)?\d+(\.\d+)?),((-|\+)?\d+(\.\d+)?)$/g;

export const projectSettingsSchema = yup
  .object()
  .shape({
    name: yup.string().required('Required'),
    ifcSchema: yup.string().required('Required'),
    zeroPoint: yup
      .string()
      .test(
        'wrong format',
        'Input has to be coordinates separated by commas, eg. "1.23, 4, 56.78"',
        value => value.replace(/\s/g, '').match(zeroPointRegex)
      )
      .required('Zero point is required, for default write "0, 0, 0"'),
  })
  .noUnknown(true);

export const projectFormSchema = yup
  .object()
  .shape({
    organizationId: yup.string().required('Required'),
    name: yup.string().required('Required'),
    netArea: yup
      .number()
      .min(1, 'Must be at least 1')
      .required('Required'),
    ifcSchema: yup.string().required('Required'),
  })
  .noUnknown(true);
