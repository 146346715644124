import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

const history = createBrowserHistory();

export const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history });
