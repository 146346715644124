import * as L from 'partial.lenses';
import { combineReducers } from 'redux';
import { INITIAL, FULFILLED } from 'utils/useAsyncCallback';
import { uploads, uploadProgressView } from './upload';
import { filterPanelState } from './filters';
import { projectDetails } from './projectDetails';
import { handleActions, handleAction } from 'redux-actions';
import {
  setUserDetails,
  setPermissions,
  setProjectPermission,
  setSelectedProjectId,
  showModal,
  pushModal,
  popModal,
  setOrgList,
  setOrg,
  setAcl,
  setProjectList,
  setActiveProject,
  addProject,
  setUseCases,
  setActiveUseCase,
} from '../actions/creators';
import { modalClosed } from '../actions/constants';
import { activation, authState, signupState, workflows } from './saga';
import { logoutRequest } from 'redux/actions/saga';
import { routerReducer } from 'redux/router';

// Reducers specify how the application's state changes in response
// to actions sent to the store.
// The reducer is a pure function that takes the previous
// state and an action, and returns the next state.
// Detailed documentation: https://redux.js.org/basics/redux/reducers

// Reducers
const userDetails = handleAction(setUserDetails, (_, { payload }) => payload, {
  status: INITIAL,
  response: {},
});

const permissions = handleActions(
  {
    [setPermissions]: (_, { payload }) => payload,
    [setProjectPermission]: (state, { payload }) =>
      L.set(['response', payload], 'admin', state),
  },
  { status: INITIAL, response: {} }
);

const selectedProjectId = handleAction(
  setSelectedProjectId,
  (_, { payload }) => payload,
  ''
);

const newPermissions = handleActions(
  {
    [setAcl]: (_, { payload }) => payload,
  },
  { status: FULFILLED, response: {} }
);

const modals = handleActions(
  {
    [showModal]: ([modalProps], { payload }) =>
      payload.type
        ? [{ show: true, ...payload }]
        : [{ ...modalProps, ...modalClosed }],
    [pushModal]: (
      [prevModal, ...rest],
      { payload: { modalProps, prevModalProps } }
    ) => [
      { show: true, isPushed: true, ...modalProps },
      { ...prevModal, ...prevModalProps },
      ...rest,
    ],
    [popModal]: (modals, { payload = state => state }) =>
      modals.length === 1
        ? [{ ...modals[0], ...modalClosed }]
        : [payload(modals[1]), ...modals.slice(2)],
  },
  [modalClosed]
);

const orgs = handleActions(
  {
    [setOrgList]: (_, { payload }) => payload,
    [setOrg]: (state, { payload: { id, org } }) =>
      L.set(['response', id, L.assignTo], org, state),
  },
  { status: INITIAL, response: {} }
);

const projectList = handleActions(
  {
    [addProject]: (state, { payload }) =>
      L.set(['response', L.appendTo], payload, state),
    [setProjectList]: (state, { payload }) => L.assign([], payload, state),
    [setActiveProject]: (state, { payload }) =>
      L.set('activeProject', payload, state),
  },
  {
    status: INITIAL,
    activeProject: null,
    response: [],
  }
);

const useCases = handleActions(
  {
    [setUseCases]: (state, { payload }) => L.assign([], payload, state),
    [setActiveUseCase]: (state, { payload }) =>
      L.set('activeUseCase', payload, state),
  },
  {
    status: INITIAL,
    response: [],
    activeUseCase: null,
  }
);

const appReducer = combineReducers({
  router: routerReducer,
  authState,
  signupState,
  selectedProjectId,
  userDetails,
  permissions,
  newPermissions,
  uploads,
  uploadProgressView,
  projectDetails,
  modals,
  filterPanelState,
  orgs,
  projectList,
  activation,
  workflows,
  useCases,
});

const rootReducer = (state, action) =>
  appReducer(
    action.type === logoutRequest.toString() ? { router: state.router } : state,
    action
  );

export default rootReducer;
