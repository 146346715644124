// Various config options shall be placed here (API keys, URLs, etc...)

export const API_URL = process.env.REACT_APP_API_HOST;
export const HOST_URL = process.env.REACT_APP_HOST;
export const CDN_URL = process.env.REACT_APP_CDN_HOST;

// API KEY used during authentication
export const API_KEY = process.env.REACT_APP_API_KEY;

// The maximum file size for uploads in megabytes
export const FILE_UPLOAD_LIMIT = process.env.REACT_APP_FILE_UPLOAD_LIMIT;

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;

export const LOG_STUFF =
  process.env.REACT_APP_LOG_STUFF && process.env.NODE_ENV !== 'test';
