import { getJSONWithToken } from '../utils';
import * as yup from 'yup';

const FETCH_PERMISSION_ERROR = 'Could not fetch permissions.';

const permissionObjectSchema = yup
  .object()
  .shape({
    id: yup.string().required(),
    userGroups: yup
      .array()
      .of(yup.string())
      .required(),
  })
  .noUnknown(true);

const permissionSchema = yup
  .array()
  .of(permissionObjectSchema)
  .ensure();

export const fetchPermissions = token =>
  getJSONWithToken(
    'permissions',
    token,
    FETCH_PERMISSION_ERROR,
    permissionSchema
  );

const aclItemSchema = yup.object().shape({
  notice: yup
    .array()
    .of(yup.string().required())
    .ensure(),
  features: yup
    .object()
    .values(yup.string().oneOf(['available', 'modal', 'content-block']))
    .required(),
});

const aclSchema = yup.object().shape({
  projects: yup.object().values(aclItemSchema),
});

export const fetchACLStuff = token =>
  getJSONWithToken('acl', token, 'An error occured', aclSchema);
