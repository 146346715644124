import { useEffect } from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from 'redux/actions/saga';

const LogOut = ({ logoutRequest }) => {
  useEffect(() => {
    logoutRequest();
  }, [logoutRequest]);

  return null;
};

const mapDispatchToProps = { logoutRequest };

export default connect(
  null,
  mapDispatchToProps
)(LogOut);
