import { call, put, select } from 'redux-saga/effects';
import { logoutRequest } from 'redux/actions/saga';
import { FULFILLED, PENDING } from 'utils/useAsyncCallback';

export const fetchAndSaveSagaWrapper = ({
  setter,
  selector = null,
  initialState = {},
  fetchFn,
  transformApiResult = null,
  transformArgs = null,
}) => {
  return function*(...args) {
    try {
      let state = [];

      if (selector) {
        state = yield select(selector);
      }

      yield put(setter(PENDING, initialState));

      const argsToPass = transformArgs ? transformArgs(...args) : args;

      const apiResult = yield call(fetchFn, ...state, ...argsToPass);

      const result = transformApiResult
        ? transformApiResult(apiResult)
        : apiResult;

      yield put(setter(FULFILLED, result));
    } catch (error) {
      const isJWTExpired = error.code === 401 && error.message.includes('jwt');

      if (isJWTExpired) {
        yield put(logoutRequest());
      }

      yield put(
        setter({ message: error.message, code: error.code }, initialState)
      );
    }
  };
};
