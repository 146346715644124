import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import reducers from './reducers';
import { createReduxHistory, routerMiddleware } from './router';

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();

// Redux devtools integration & compose
// see https://github.com/zalmoxisus/redux-devtools-extension
// & https://redux.js.org/glossary#store-enhancer
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// We create the store that holds the app state
// By default, redux only supports synchronous data flow
// So we also apply the thunk middleware to help us express async actions
// https://redux.js.org/basics/store
// https://redux.js.org/advanced/async-flow
export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(routerMiddleware, thunk, sagaMiddleware))
);

export const history = createReduxHistory(store);
