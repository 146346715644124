import axios from 'axios';
import { API_URL, API_KEY, LOG_STUFF } from './config';
import * as yup from 'yup';
import { format, formatDistanceToNow, subDays, isBefore } from 'date-fns';
import { Mixpanel } from './MixpanelUtils/MixpanelActions';
import { CANCEL } from '@redux-saga/core';

const noValidation = yup
  .mixed()
  .test('no-validation', 'no validation', () => true);

// Extend yup.object with custom method
yup.addMethod(yup.object, 'values', function(schema, options) {
  return this.test('object values', function(obj) {
    if (!obj) {
      return true;
    }
    for (const key of Object.keys(obj)) {
      try {
        schema.validateSync(obj[key], options);
      } catch (err) {
        const path = this.path ? `${this.path}.${key}` : key;
        const message = err.errors.map(msg =>
          msg.replace(/[^\s]+/, value =>
            value === 'this' ? path : `${path}.${value}`
          )
        );
        return this.createError({ path, message });
      }
    }
    return true;
  });
});

// Fetching related utilities

// A highly abstract, reusable and configurable utility function that deals with
// JSON requests
// In case of an error, it returns the error message sent back from
// the server (if present) or a custom error message that is passed through
// as an argument
const request = async (
  endpoint,
  config,
  customError,
  schema = noValidation,
  trackErrors = true
) => {
  try {
    const { data } = await axios({
      ...config,
      url: `${API_URL}/${endpoint}`,
      withCredentials: true,
    });
    return await schema.validate(data);
  } catch (error) {
    if (LOG_STUFF) {
      console.log(endpoint);
      console.log(error);
    }

    const err = new Error(
      error?.response?.data?.message ||
        error?.response?.data?.reason ||
        customError
    );
    err.code = error?.response?.status;
    if (trackErrors) {
      Mixpanel.requestErrors(
        endpoint,
        `${err.message} ${config?.data?.email ?? ''}`,
        err.code
      );
    }

    throw err;
  }
};

export const requestJSON = (
  endpoint,
  config,
  customError,
  schema,
  trackErrors
) => {
  const source = axios.CancelToken.source();
  const promise = request(
    endpoint,
    { ...config, cancelToken: source.token },
    customError,
    schema,
    trackErrors
  );
  promise[CANCEL] = () => source.cancel();
  return promise;
};

// A reusable utility function that gets JSON data from a particular endpoint
// with the token present in the authorization header
// Used for all GET requests once a user has been authenticated and issued
// a token
export const getJSONWithToken = (endpoint, token, customError, schema) =>
  requestJSON(
    endpoint,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
    customError,
    schema
  );

// A reusable utility function that posts JSON data to a particular endpoint
// with the API key attached in the authorization header
// Used for auth related stuff when a token is not yet returned from the backend
export const postJSON = (endpoint, payload, customError) =>
  requestJSON(
    endpoint,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_KEY}`,
      },
      data: payload,
    },
    customError
  );

// Same as above, except it adds the token to the auth header
// Used for all POST requests once a user has been authenticated and issued
// a token
export const postJSONWithToken = (
  endpoint,
  token,
  payload,
  customError,
  schema,
  trackErrors = true
) =>
  requestJSON(
    endpoint,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    },
    customError,
    schema,
    trackErrors
  );

// Same as above, except it's a PATCH request
export const patchJSONWithToken = (endpoint, token, payload, customError) =>
  requestJSON(
    endpoint,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    },
    customError
  );

export const deleteWithToken = (endpoint, token, customError) =>
  requestJSON(
    endpoint,
    {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${token}` },
    },
    customError
  );

// generic form data posting utility
// setStatus is optional (it tracks upload progress)
export const sendFormData = async (token, endpoint, payload, setStatus) => {
  const formData = new FormData();
  Object.keys(payload).forEach(key => formData.append(key, payload[key]));

  const defaultConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    url: `${API_URL}/${endpoint}`,
    data: formData,
  };

  const onUploadProgress = ({ loaded, total }) =>
    setStatus({ progress: Math.round((loaded * 100) / total) });

  const config = setStatus
    ? { ...defaultConfig, onUploadProgress }
    : defaultConfig;

  const { data } = await axios(config);
  return data;
};

// Data manipulation utils
export const convertToPercent = n =>
  isNaN(n) || n === Infinity ? 100 : Math.floor(n * 100);

const getAvg = arr =>
  arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
  arr.length;

export const getAvgPercent = arr => convertToPercent(getAvg(arr));

export const groupBy = (fn, arr) =>
  arr.reduce((accumulator, currentValue) => {
    const key = fn(currentValue);

    accumulator[key]
      ? accumulator[key].push(currentValue)
      : (accumulator[key] = [currentValue]);

    return accumulator;
  }, {});

// Map over objects
export const mapObj = (fn, obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = fn(obj[key], key);
    return acc;
  }, {});

// Finds and returns the difference between two objects with the same keys
export const objDifference = (obj1, obj2) =>
  Object.keys(obj1).reduce((accumulator, currentValue) => {
    if (obj1[currentValue] !== obj2[currentValue]) {
      accumulator[currentValue] = obj2[currentValue];
    }
    return accumulator;
  }, {});

export const filterEmptyValuesFromObject = obj =>
  Object.keys(obj).reduce((accumulator, currentValue) => {
    if (obj[currentValue]) {
      accumulator[currentValue] = obj[currentValue];
    }
    return accumulator;
  }, {});

export const getExtension = fileName =>
  fileName.match(/(\.\w+)$/)?.[1].toLowerCase();

// Switch an object keys with its values
export const invertObject = obj =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    acc[value] = key;
    return acc;
  }, {});

export const truncate = (str, limit) =>
  str.length > limit ? str.slice(0, limit - 3) + '...' : str;

// Create a filter function that excludes the elements of the specified array
// const filter = exclude([1,2]);
// filter([1,2,3,2,1]) => [3]
export const exclude = excludedElements => elements =>
  elements.filter(el => !excludedElements.includes(el));

// Human readable sizes
export const displaySize = size =>
  size < 2 ** 20 ? `${size >> 10} kB` : `${size >> 20} Mb`;

export const pluraliseWithS = (str, count, showCount) =>
  showCount
    ? `${count} ${str}${count > 1 ? 's' : ''}`
    : `${str}${count > 1 ? 's' : ''}`;

export const maximumBy = (fn, arr) =>
  arr?.length
    ? arr.reduce((max, current) => (fn(current) > fn(max) ? current : max))
    : undefined;

export const displayLastUpdatedAtDate = date => {
  const cutOff = subDays(new Date(), 2);
  return isBefore(date, cutOff)
    ? format(date, 'dd MMMM yyyy, HH:mm')
    : `${formatDistanceToNow(date)} ago`;
};
