import { getJSONWithToken, groupBy } from 'utils/utils';
import { workflowsSchema } from './WorkflowSchema';

const workflowSteps = [
  {
    identifier: 'ifcPreProcessor',
    name: 'Checking IFC data',
    description:
      'Schema, Version and SiteLocation of the IFC must be included for a successful upload.',
  },
  {
    identifier: 'fulfillmentCalculator',
    name: 'Calculating requirement fulfilment',
    description:
      'The file is checked against Information Requirements to update the fulfilment status.',
    link: 'req-fulfil',
  },
  {
    identifier: 'modelConverter',
    name: 'Converting model for viewer',
    description:
      'Once this is completed, you can visualise the model in our IFC Online Viewer.',
    link: 'model-viewer',
  },
  {
    identifier: 'bcfGenerator',
    name: 'Preparing BCF file',
    description:
      'Based on the Requirement Fulfilment you can check the missing building information.',
    link: 'req-fulfil',
  },
];

export const transformWorkflows = workflow => {
  const groups = groupBy(({ identifier }) => identifier, workflow.steps);

  return {
    ...workflow,
    roleName: workflow.role.toUpperCase(),
    steps: workflowSteps.map(step => {
      const stepUpdate = groups[step.identifier];
      return stepUpdate ? { ...step, ...stepUpdate[0] } : step;
    }),
  };
};

export const fetchWorkflows = (token, projectId) =>
  getJSONWithToken(
    `project/${projectId}/workflow`,
    token,
    'Could not fetch workflows.',
    workflowsSchema
  );
