export const trackPageLoadHubspot = pageName => {
  if (window) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', pageName]);
    _hsq.push(['trackPageView']);
  }
};

export const registerUserHubspot = (email, id) => {
  if (window) {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      'identify',
      {
        email,
        id,
      },
    ]);
  }
};
