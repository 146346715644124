import { all, put } from 'redux-saga/effects';
import { loginWithToken } from 'redux/actions/saga';
import { authorisationFlow } from 'redux/sagas/authFlow/authFlow';
import { deviceActivationFlow } from './authFlow/deviceActivation';
import { signupFlow } from './signupFlow';
import { workflowsFlow } from './workflows';

export function* readToken() {
  const token = localStorage.getItem('authToken');
  if (token) {
    yield put(loginWithToken(token));
  }
}

export default function* rootSaga() {
  yield all([
    readToken(),
    authorisationFlow(),
    signupFlow(),
    deviceActivationFlow(),
    workflowsFlow(),
  ]);
}
