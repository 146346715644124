import { LOCATION_CHANGE, push } from 'redux-first-history';
import {
  all,
  call,
  cancelled,
  delay,
  put,
  race,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  connectDevice,
  loginWithToken,
  setCode,
  setTimer,
} from 'redux/actions/saga';
import { getCode, connect } from 'utils/AuthUtils/AuthUtils';

const maxRetries = 10;
const timeBetweenRetries = 2000; // 2 sec

export function* deviceActivationFlow() {
  yield takeLatest(connectDevice, tryDeviceActivation);
}

// cancel activation attempt/retry on location change
function* tryDeviceActivation() {
  yield race([call(deviceActivation), take(LOCATION_CHANGE)]);
}

function* deviceActivation() {
  let retries = 0;
  while (true) {
    try {
      yield put(setTimer(false));
      const { code } = yield call(getCode);
      yield all([put(setCode(code)), put(setTimer(true))]);
      const { token } = yield call(connect, code);
      yield put(loginWithToken(token));
      yield put(push('/'));
    } catch (error) {
      // limit retries on server errors
      if (error.code >= 500) {
        retries++;
        yield delay(timeBetweenRetries);
        if (retries > maxRetries) {
          return yield put(setCode(''));
        }
      }
    } finally {
      if (yield cancelled()) {
        yield put(setCode(''));
      }
    }
  }
}
