import Modal from 'react-bootstrap/Modal';

const ProjectNotFoundModal = () => (
  <>
    <Modal.Header closeButton>
      <Modal.Title>Project not found</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Unfortunately, the project you were looking for could not be found. You
      will be now redirected to the demo project. Please dismiss this alert.
    </Modal.Body>
  </>
);

export default ProjectNotFoundModal;
