import { lazy, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { PrivateRoute } from 'utils/PrivateRoute';
import { NoMatch } from 'utils/renderUtils';
import { trackPageLoadHubspot } from 'utils/hubspotUtils';

import LogOut from 'Auth/LogOut';

import ProjectHome from 'ProjectDashboard/ProjectHome';

import NoProject from './Projects/NoProject';
import AuthErrorScreen from 'Auth/AuthErrorScreen';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'Auth/Login'));
const Signup = lazy(() =>
  import(/* webpackChunkName: "Signup" */ 'Auth/Signup/Signup')
);
const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "ForgotPassword" */ 'Auth/ForgotPassword')
);
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ 'Auth/ResetPassword')
);
const Verify = lazy(() =>
  import(/* webpackChunkName: "Verify" */ 'Auth/Verify')
);
const Connect = lazy(() =>
  import(/* webpackChunkName: "Connect" */ 'Auth/Connect')
);
const Activate = lazy(() =>
  import(/* webpackChunkName: "Activate" */ 'Auth/Activate')
);

const OrganisationDashboard = lazy(() =>
  import(
    /* webpackChunkName: "OrganisationDashboard" */ 'OrganisationDashboard/OrganisationDashboard'
  )
);

const ProjectDashboard = lazy(() =>
  import(
    /* webpackChunkName: "ProjectDashboard" */ 'ProjectDashboard/ProjectDashboard'
  )
);

const CreateProject = lazy(() =>
  import(/* webpackChunkName: "CreateProject" */ './Projects/CreateProject')
);

const wrapComponent = Component => props => (
  <div className="bmspt-w-full bmspt-mx-auto">
    <Component {...props} />
  </div>
);

const ProjectRedirect = ({ match }) => (
  <Redirect to={`/project/${match.params.route ?? ''}`} />
);

const routes = [
  { path: '/login', component: Login, exact: true },
  { path: '/connect', component: Connect, exact: true },
  {
    path: '/activate/:code?',
    component: Activate,
    exact: true,
    private: true,
  },
  { path: '/sign-up', component: Signup, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/reset-password', component: ResetPassword, exact: true },
  { path: '/verify', component: Verify, exact: true },
  { path: '/auth-error', component: AuthErrorScreen, exact: true },
  {
    path: '/',
    component: ProjectHome,
    exact: true,
    private: true,
  },
  { path: '/project', component: ProjectDashboard, private: true },
  { path: '/dashboard/:route*', component: ProjectRedirect },
  {
    path: '/organisation/:id',
    component: OrganisationDashboard,
  },
  {
    path: '/create-project',
    component: CreateProject,
    private: true,
    allowedWithoutProject: true,
  },
  {
    path: '/no-project',
    component: NoProject,
    private: true,
    allowedWithoutProject: true,
  },
  {
    path: '/logout',
    component: LogOut,
    private: true,
  },
].map(obj => ({
  ...obj,
  component: wrapComponent(obj.component),
  Route: obj.private ? PrivateRoute : Route,
}));

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    trackPageLoadHubspot(location.pathname);
  }, [location.pathname]);
};

const Routes = () => {
  usePageViews();

  return (
    <Switch>
      {routes.map(({ path, component, Route, ...props }) => (
        <Route key={path} path={path} component={component} {...props} />
      ))}
      <Route path="/">
        <NoMatch
          description="Oops, there doesn't seem to be anything here. Let us help you find your
      way. Click the button below to take you where you need to go."
          ctaText="Go to bimspot cloud platform"
        />
      </Route>
    </Switch>
  );
};
export default Routes;
