import * as L from 'partial.lenses';
import { handleActions } from 'redux-actions';
import {
  setFilterPanelCollapsedState,
  setFilterResult,
  setSelectedProjectId,
  setSavedFilters,
  setSavedFilter,
  renameFilter,
  removeFilter,
  switchToSavedFilters,
  switchToFilter,
  setReqState,
} from '../actions/creators';
import { INITIAL } from 'utils/useAsyncCallback';

const defaultState = { collapsed: true, result: null };

// Custom lenses
const when = bool => (bool ? L.identity : L.zero);

const filterNameWithId = filterId => [
  L.branch({
    savedFilter: L.identity,
    savedFilters: L.elems,
  }),
  L.when(e => e._id === filterId),
  'name',
];

// Reducers
export const filterPanelState = handleActions(
  {
    [setFilterPanelCollapsedState]: (state, { payload }) =>
      L.set('collapsed', payload, state),
    [setFilterResult]: (state, { payload }) =>
      payload
        ? L.assign('result', payload, state)
        : L.set('result', null, state),
    [setSelectedProjectId]: () => defaultState,
  },
  defaultState
);

export const savedFilters = handleActions(
  {
    [setSavedFilters]: (state, { payload }) =>
      L.set('savedFilters', payload, state),
    [setSavedFilter]: (state, { payload: { filter, options } }) =>
      L.set(
        L.branch({
          savedFilter: when(options.loadFilter),
          savedFilters: [when(options.addToList), L.prependTo],
        }),
        filter,
        state
      ),
    [renameFilter]: (state, { payload: { _id, name } }) =>
      L.set(filterNameWithId(_id), name, state),
    [removeFilter]: (state, { payload: id }) => {
      const savedFilters = state.savedFilters.filter(({ _id }) => _id !== id);
      return {
        ...state,
        page: savedFilters.length ? state.page : 1,
        savedFilters,
        savedFilter: state.savedFilter?._id === id ? null : state.savedFilter,
      };
    },
    [switchToSavedFilters]: L.set('page', 0),
    [switchToFilter]: L.set('page', 1),
    [setReqState]: (state, { payload: changesReqState }) => ({
      ...state,
      changesReqState,
      prevChangesReqState: state.changesReqState,
    }),
  },
  {
    page: 1,
    savedFilter: null,
    savedFilters: [],
    changesReqState: INITIAL,
    prevChangesReqState: INITIAL,
  }
);
