import { Link } from 'react-router-dom';
import { ButtonLink } from '@bimspot/bimspot-component-library';

import { ReactComponent as ErrorCover } from 'assets/images/empty-states/void.svg';

const AuthErrorScreen = () => (
  <div className="padded-container bmspt-flex bmspt-items-center bmspt-justify-center">
    <div className="bmspt-flex bmspt-flex-col bmspt-h-full bmspt-justify-center bmspt-items-center">
      <ErrorCover className="bmspt-max-w-full bmspt-max-h-full" />
      <h1 className="bmspt-mt-6 bmspt-text-center bmspt-text-4xl bmspt-font-bold">
        Something went wrong!
      </h1>
      <p className="bmspt-text-center">Please try to login again.</p>
      <div className="bmspt-text-center">
        <ButtonLink as={Link} className="bmspt-mt-6" to="/login">
          Go back
        </ButtonLink>
      </div>
    </div>
  </div>
);

export default AuthErrorScreen;
