import * as yup from 'yup';

export const phaseSchema = yup
  .string()
  .oneOf([
    'Pending',
    'Running',
    'Succeeded',
    'Skipped',
    'Failed',
    'Error',
    'Omitted',
  ])
  .required();

const workflowStepSchema = yup
  .object()
  .shape({
    phase: phaseSchema,
    identifier: yup.string().required(),
    startedAt: yup.date().nullable(),
    finishedAt: yup.date().nullable(),
  })
  .noUnknown(true);

const authorSchema = yup
  .object()
  .shape({
    email: yup.string().ensure(),
    firstname: yup.string(),
    lastname: yup.string(),
  })
  .noUnknown(true);

const workflowSchema = yup
  .object()
  .shape({
    author: authorSchema,
    createdAt: yup.date().required(),
    updatedAt: yup.date().required(),
    finishedAt: yup.date().nullable(),
    id: yup.string().required(),
    buildingId: yup.string().required(),
    buildingName: yup.string().required(),
    buildingPartId: yup.string().required(),
    buildingPartName: yup.string().ensure(),
    role: yup.string().required(),
    uploadId: yup.string().required(),
    projectId: yup.string().required(),
    fileName: yup.string().required(),
    fileSize: yup
      .number()
      .integer()
      .required(),
    phase: phaseSchema,
    steps: yup
      .array()
      .of(workflowStepSchema)
      .required(),
  })
  .noUnknown(true);

export const workflowsSchema = yup
  .object()
  .shape({
    workflows: yup
      .array()
      .of(workflowSchema)
      .ensure(),
    count: yup
      .number()
      .integer()
      .required(),
  })
  .noUnknown(true);
