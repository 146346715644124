import { setProjectDetails, setProjectList } from 'redux/actions/creators';
import {
  fetchProjectDetails,
  fetchProjectList,
} from 'utils/ProjectUtils/ProjectUtils';
import { fetchAndSaveSagaWrapper } from './utils';

const selectFn = ({ authState: { token }, selectedProjectId }) => [
  token,
  selectedProjectId,
];

export const fetchAndSaveProjectDetails = fetchAndSaveSagaWrapper({
  selector: selectFn,
  setter: setProjectDetails,
  fetchFn: fetchProjectDetails,
  initialState: { buildings: [] },
});

export const fetchAndSaveProjectList = fetchAndSaveSagaWrapper({
  selector: ({ authState: { token } }) => [token],
  setter: setProjectList,
  fetchFn: fetchProjectList,
  initialState: [],
});
