// Some key highlights of actions/action creators:
// Actions are payloads of information that send data from the
// application to the store.
// They are the only source of information for the store.
// Action creators are functions that return actions.
// Actions are plain JavaScript objects
// For more, refer to: https://redux.js.org/basics/redux/actions

// To reduce boilerplate, we use redux-actions
// https://redux-actions.js.org/

import { createActions } from 'redux-actions';

// Auth related
export const { setAuthToken, setUserDetails, setPermissions } = createActions({
  SET_AUTH_TOKEN: authToken => authToken,
  SET_USER_DETAILS: (status, userDetails) => ({
    response: userDetails,
    status,
  }),
  SET_PERMISSIONS: (status, permissions) => ({
    response: permissions,
    status,
  }),
});

// Upload related
export const {
  newUpload,
  setUploadReqstate,
  setUploadProgress,
  clearUploads,
} = createActions({
  NEW_UPLOAD: (
    uploadId,
    projectId,
    fileObject,
    selectedPart,
    cancelTokenSource
  ) => ({
    uploadId,
    projectId,
    fileObject,
    selectedPart,
    cancelTokenSource,
  }),
  SET_UPLOAD_REQSTATE: (uploadId, reqState) => ({ uploadId, reqState }),
  SET_UPLOAD_PROGRESS: (uploadId, uploadProgress) => ({
    uploadId,
    uploadProgress,
  }),
  CLEAR_UPLOADS: null,
});

export const {
  switchUploadProgressView,
  setUploadProgressView,
} = createActions({
  SWITCH_UPLOAD_PROGRESS_VIEW: null,
  SET_UPLOAD_PROGRESS_VIEW: uploadProgressView => uploadProgressView,
});

// Project related
export const { setSelectedProjectId, setProjectPermission } = createActions({
  SET_SELECTED_PROJECT_ID: projectId => projectId,
  SET_PROJECT_PERMISSION: projectId => projectId,
});

// Model management related
export const {
  setProjectDetails,
  addBuilding,
  changeBuildingName,
  addBuildingPart,
  changeBuildingPartName,
  removeBuildingPart,
} = createActions({
  SET_PROJECT_DETAILS: (status, projectDetails) => ({
    status,
    response: projectDetails,
  }),
  ADD_BUILDING: building => building,
  CHANGE_BUILDING_NAME: (id, name) => ({ id, name }),
  ADD_BUILDING_PART: (buildingId, roleId, buildingPart) => ({
    buildingId,
    roleId,
    buildingPart,
  }),
  CHANGE_BUILDING_PART_NAME: (buildingId, roleId, buildingPart) => ({
    buildingId,
    roleId,
    buildingPart,
  }),
  REMOVE_BUILDING_PART: (buildingId, roleId, buildingPartId) => ({
    buildingId,
    roleId,
    buildingPartId,
  }),
});

export const { showModal, pushModal, popModal } = createActions({
  SHOW_MODAL: modalProps => modalProps,
  PUSH_MODAL: (modalProps, prevModalProps = {}) => ({
    modalProps,
    prevModalProps,
  }),
  POP_MODAL: fn => fn,
});

// Filter related
export const { setFilterPanelCollapsedState, setFilterResult } = createActions({
  SET_FILTER_PANEL_COLLAPSED_STATE: collapsed => collapsed,
  SET_FILTER_RESULT: result => result,
});

export const {
  setSavedFilters,
  setSavedFilter,
  renameFilter,
  removeFilter,
  switchToSavedFilters,
  switchToFilter,
  setReqState,
} = createActions({
  SET_SAVED_FILTERS: filters => filters,
  SET_SAVED_FILTER: (filter, options) => ({
    filter,
    options: { addToList: false, loadFilter: true, ...options },
  }),
  RENAME_FILTER: (_id, name) => ({ _id, name }),
  REMOVE_FILTER: id => id,
  SWITCH_TO_SAVED_FILTERS: null,
  SWITCH_TO_FILTER: null,
  SET_REQ_STATE: reqState => reqState,
});

// Organisation related
export const { setOrgList, setOrg } = createActions({
  SET_ORG_LIST: (status, orgList) => ({ status, response: orgList }),
  SET_ORG: (id, org) => ({ id, org }),
});

// New permissions
export const { setAcl } = createActions({
  SET_ACL: (status, permissions) => ({
    response: permissions,
    status,
  }),
});

// Project list
export const {
  fetchProjectList,
  addProject,
  setProjectList,
  setActiveProject,
} = createActions({
  FETCH_PROJECT_LIST: null,
  ADD_PROJECT: project => project,
  SET_PROJECT_LIST: (status, projects) => ({ status, response: projects }),
  SET_ACTIVE_PROJECT: project => project,
});

// Use-cases
export const { addUseCase, setUseCases, setActiveUseCase } = createActions({
  ADD_USE_CASE: useCase => useCase,
  SET_USE_CASES: (status, useCases) => ({ status, response: useCases }),
  SET_ACTIVE_USE_CASE: useCase => useCase,
});
