import { handleActions } from 'redux-actions';
import * as L from 'partial.lenses';

import {
  setProjectDetails,
  addBuilding,
  changeBuildingName,
  addBuildingPart,
  changeBuildingPartName,
  removeBuildingPart,
} from '../actions/creators';
import { INITIAL } from 'utils/useAsyncCallback';

// Custom lenses
const buildings = ['response', 'buildings'];
const elemsWithId = id => [L.elems, L.when(e => e.id === id)];

const building = buildingId => [buildings, elemsWithId(buildingId)];

const buildingParts = (buildingId, roleId) => [
  building(buildingId),
  'roles',
  elemsWithId(roleId),
  'buildingParts',
];

const buildingPart = (buildingId, roleId, buildingPartId) => [
  buildingParts(buildingId, roleId),
  elemsWithId(buildingPartId),
];

export const projectDetails = handleActions(
  {
    [setProjectDetails]: (_, { payload }) => payload,
    [addBuilding]: (state, { payload }) =>
      L.set([buildings, L.appendTo], payload, state),
    [changeBuildingName]: (state, { payload: { id, name } }) =>
      L.set([building(id), 'name'], name, state),
    [addBuildingPart]: (
      state,
      { payload: { buildingId, roleId, buildingPart } }
    ) =>
      L.set(
        [buildingParts(buildingId, roleId), L.appendTo],
        buildingPart,
        state
      ),
    [changeBuildingPartName]: (
      state,
      { payload: { buildingId, roleId, buildingPart: part } }
    ) => L.assign(buildingPart(buildingId, roleId, part.id), part, state),
    [removeBuildingPart]: (
      state,
      { payload: { buildingId, roleId, buildingPartId } }
    ) => L.remove(buildingPart(buildingId, roleId, buildingPartId), state),
  },
  { status: INITIAL, response: { buildings: [] } }
);
