import {
  postJSON,
  requestJSON,
  getJSONWithToken,
  postJSONWithToken,
} from '../utils';
import { API_KEY } from '../config';
import { deviceAuthCodeSchema, deviceAuthTokenSchema } from './AuthSchema';

// Custom errors
const AUTH_ERROR = 'Authentication failed.';
const SIGNUP_ERROR = 'Could not register user.';
const RESET_PW_ERROR = 'Could not reset password';
const SET_PW_ERROR = 'Could not set new password.';
const UPDATE_PW_ERROR = 'Could not update password.';
const VERIFY_ERROR = 'Could not verify user.';
const TWOFA_ERROR = 'Could not complete 2FA.';
const LOGOUT_ERROR = 'Could not log user out.';
const CONNECT_ERROR = 'Could not connect';

// Login step 1
// A function used to authenticate users
// Takes in as parameter a user credentials object of the following shape:
// { email: "email", password: "password" }
export const authUserStep1 = credentials =>
  postJSON('authenticate', credentials, AUTH_ERROR);

// Login step 2
// Used in 2FA verification
// Takes in as parameter the intermediate token and the 2FA verification code
// The intermediate token is attached to the authorization header
// The verification code is sent as an object of the following shape:
// { token: "verificationCode" }
export const authUserStep2 = (intermediateToken, verificationCode) =>
  requestJSON(
    '2fa',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${intermediateToken}`,
      },
      data: { token: verificationCode },
    },
    TWOFA_ERROR
  );

// A function to log the user out
export const logUserOut = token =>
  getJSONWithToken('logout', token, LOGOUT_ERROR);

// A function that creates a new user
// Takes in as parameter a query string & a user object of the following shape:
// { email: "email", password: "password" }
export const createNewUser = (search, userObj) =>
  postJSON('register' + getUtmParams(search), userObj, SIGNUP_ERROR);

// A function that resets a user's password
// Takes in as parameter an email object of the following shape:
// { email: "email" }
export const resetPassword = email =>
  postJSON('reset-password', email, RESET_PW_ERROR);

// A function that sets a new password for a given user
// Takes in as parameter an object of the following shape:
// { requestId: "requestId", password: "password" }
export const setPassword = reqBody =>
  postJSON('password', reqBody, SET_PW_ERROR);

export const updatePassword = (token, reqBody) =>
  postJSONWithToken('password', token, reqBody, UPDATE_PW_ERROR);

// A function that verifies a given user with the corresponding uuid
// Takes in as parameter a params object of the following shape:
// { u: "uuid" }
export const verifyUser = params =>
  requestJSON(
    'verify',
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      params,
    },
    VERIFY_ERROR
  );

export const getCode = params =>
  requestJSON(
    'connect',
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      params,
    },
    CONNECT_ERROR,
    deviceAuthCodeSchema,
    false
  );

export const connect = code =>
  requestJSON(
    'connect',
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      data: { code },
    },
    CONNECT_ERROR,
    deviceAuthTokenSchema,
    false
  );

export const activate = (token, payload) =>
  postJSONWithToken('activate', token, payload, CONNECT_ERROR);

// You might have noticed the ES5 compliant code sytle for this feature.
// As ugly as it is, I intend to reuse it for bimspot.io which doesn't have
// any build/transpile steps.
export function getUtmParams(params) {
  if (params) {
    const utm = params
      .slice(1)
      .split('&')
      .filter(key => key.indexOf('utm_') === 0)
      .join('&');
    return utm ? '?' + utm : '';
  }
  return '';
}
