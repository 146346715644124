// Rendering related utilities
import { createContext, useContext } from 'react';
import { Alert, Button, ButtonLink } from '@bimspot/bimspot-component-library';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { ReactComponent as BimspotLogo } from 'assets/images/bimspot_logo_symbol.svg';
import { ReactComponent as NoMatchCover } from 'assets/images/empty-states/void.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/action-more.svg';

import {
  FULFILLED,
  PENDING,
  INITIAL,
  isSuccess,
  isError,
  combineReqStates,
} from './useAsyncCallback';
import { Mixpanel } from './MixpanelUtils/MixpanelActions';
import ProjectPermissionAwareWrapper from './PermissionUtils/ProjectPermissionAwareWrapper';

export const ErrorComponent = ({ msg, info, ...rest }) => (
  <div {...rest}>
    <Alert variant="error">
      {msg && <Alert.Header>{msg}</Alert.Header>}
      {info && (
        <Alert.Description>
          <p className="bmspt-text-sm">{info}</p>
        </Alert.Description>
      )}
    </Alert>
  </div>
);

export const AuthErrorComponent = props => (
  <ErrorComponent
    className="bmspt-mx-auto bmspt-my-12 bmspt-max-w-[540px]"
    {...props}
  />
);

export const AuthFormWrapper = ({ children, reqState }) => {
  return isError(reqState) ? (
    <>
      <AuthErrorComponent
        msg={reqState.message || 'An unknown error occurred, please try again.'}
      />
      {children}
    </>
  ) : (
    children
  );
};

export const SuccessComponent = ({
  msg,
  info = "Oh yeah! We're on the right track!",
  ...rest
}) => (
  <div {...rest}>
    <Alert variant="success">
      {msg && <Alert.Header>{msg}</Alert.Header>}
      {info && (
        <Alert.Description>
          <p className="bmspt-text-sm">{info}</p>
        </Alert.Description>
      )}
    </Alert>
  </div>
);

export const AuthSuccessComponent = props => (
  <SuccessComponent
    className="bmspt-mx-auto bmspt-my-12 bmspt-max-w-[540px]"
    {...props}
  />
);

export const renderUnit = unit => (
  <>
    {`${unit.substring(0, unit.length - 1)}`}
    <sup>{`${unit.substring(unit.length - 1)}`} </sup>
  </>
);

// The renderBasedOnReqState takes as parameter the request state,
// a loading, success and an error component
// Based on the request state, it returns
// a) a loading component
// b) the component with the actual data
// c) an error component
export const renderBasedOnReqState = (
  state,
  LoadingComponent,
  SuccessComponent,
  ErrorComponent
) => {
  switch (state) {
    case INITIAL:
      return null;
    case PENDING:
      return LoadingComponent;
    case FULFILLED:
      return SuccessComponent;
    default:
      return ErrorComponent instanceof Error
        ? ErrorComponent.message
        : ErrorComponent;
  }
};

export const AsyncContext = createContext({ reqState: INITIAL });

export const AsyncProvider = ({
  children,
  onError,
  activeState = 'reqState',
  ...props
}) => {
  const reqState =
    activeState === 'all'
      ? combineReqStates(Object.values(props))
      : props[activeState];
  return isError(reqState) ? (
    onError === undefined ? (
      reqState?.message
    ) : (
      onError
    )
  ) : (
    <AsyncContext.Provider value={props}>{children}</AsyncContext.Provider>
  );
};

export const LoadingSkeleton = ({
  className,
  children,
  activeState = 'reqState',
  onError,
  customSkeleton,
  ...props
}) => {
  const context = useContext(AsyncContext);
  const reqState =
    activeState === 'all'
      ? combineReqStates(Object.values(context))
      : context[activeState];

  if (isSuccess(reqState)) {
    return children ?? null;
  }

  if (isError(reqState)) {
    return onError === undefined ? reqState?.message : onError;
  }

  return className ? (
    <div className={className}>{customSkeleton || <Skeleton {...props} />}</div>
  ) : (
    customSkeleton || <Skeleton {...props} />
  );
};

const mapStateToProps = ({ selectedProjectId }) => ({ selectedProjectId });

const mixpanelUploadModelClick = () => {
  Mixpanel.trackWithTime('Upload model button click', {
    clicked_on: 'project cockpit',
  });
};

const EmptyStateCoverComponent = ({
  coverImage: CoverImage,
  openUploadDialog,
  cta,
  description,
  selectedProjectId,
  isLink,
}) => (
  <div className="bmspt-flex bmspt-flex-col bmspt-h-full bmspt-justify-center bmspt-items-center">
    <CoverImage className="bmspt-max-w-full bmspt-max-h-full" />
    <p className="bmspt-mt-6 bmspt-mb-2 bmspt-text-4xl bmspt-font-bold bmspt-text-center">
      {cta}
    </p>
    <p
      className={classnames('bmspt-text-sm bmspt-text-center', {
        'bmspt-mb-4': !isLink,
      })}
    >
      {description}
    </p>
    {isLink && (
      <p className="bmspt-mb-4 bmspt-text-sm bmspt-text-center">
        Go to <span className="bmspt-font-bold">Workflows</span> to check the
        status of your IFC file upload.
      </p>
    )}
    {isLink ? (
      <ButtonLink as={Link} to={`/project/${selectedProjectId}/workflows`}>
        Go to Workflows
      </ButtonLink>
    ) : (
      <ProjectPermissionAwareWrapper feature="modelUpload" noContentBlock>
        <Button
          onClick={() => {
            openUploadDialog();
            mixpanelUploadModelClick();
          }}
        >
          Upload my model
        </Button>
      </ProjectPermissionAwareWrapper>
    )}
  </div>
);

export const EmptyStateCover = connect(mapStateToProps)(
  EmptyStateCoverComponent
);

export const NoMatch = ({ description, ctaText }) => (
  <div className="bmspt-flex bmspt-flex-col padded-container bmspt-min-h-screen bmspt-justify-center bmspt-items-center">
    <NoMatchCover className="bmspt-max-w-full bmspt-max-h-full" />
    <h1 className="bmspt-mt-6 bmspt-text-4xl bmspt-font-bold bmspt-text-center">
      404
    </h1>
    <p className="bmspt-max-w-3xl bmspt-px-12 bmspt-text-center bmspt-font-normal">
      {description}
    </p>
    <ButtonLink as={Link} className="bmspt-mt-4" to="/">
      {ctaText}
    </ButtonLink>
  </div>
);

// Highlights matched text
export const highlightText = (str, matchedStr) => {
  const parts = str
    .split(new RegExp(`(${matchedStr})`, 'gi'))
    .filter(item => item.length);
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === matchedStr.toLowerCase()
              ? { fontWeight: 'bold' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const Avatar = ({ email, firstname, lastname, className }) => {
  const shouldUseFullName = firstname && lastname;

  return (
    <div
      className={classnames(
        'bmspt-flex bmspt-flex-shrink-0 bmspt-justify-center bmspt-items-center bmspt-w-7 bmspt-h-7 bmspt-rounded-full bmspt-select-none bmspt-bg-purple-600',
        className
      )}
    >
      <p
        className={classnames('bmspt-uppercase bmspt-text-white', {
          'bmspt-text-base': !shouldUseFullName,
          'bmspt-text-xs': shouldUseFullName,
        })}
      >
        {shouldUseFullName
          ? `${firstname.charAt(0)}${lastname.charAt(0)}`
          : email?.charAt(0)}
      </p>
    </div>
  );
};

export const LoadingBimspotLogo = ({ isFullScreen = true }) => (
  <div
    className={classnames(
      'bmspt-inset-0 bmspt-flex bmspt-justify-center bmspt-z-1',
      {
        'bmspt-fixed bmspt-inset-x-0 bmspt-top-0 bmspt-bg-white': isFullScreen,
        'bmspt-absolute': !isFullScreen,
      }
    )}
  >
    <BimspotLogo className="animated-fade" width="80px" />
  </div>
);

export const DropdownItemContentWithIcon = ({
  svgIcon: Icon,
  label,
  isDestructive,
}) => (
  <div
    className={classnames(
      'bmspt-flex bmspt-items-center bmspt-pr-8 bmspt-text-xs',
      { 'bmspt-text-red-600': isDestructive }
    )}
  >
    <Icon
      className={classnames('bmspt-w-5 bmspt-h-5 bmspt-block bmspt-mr-3', {
        'bmspt-text-gray-500': !isDestructive,
        'bmspt-text-red-500': isDestructive,
      })}
    />
    <span>{label}</span>
  </div>
);

export const MoreOptionsToggle = () => (
  <MoreIcon className="bmspt-w-4 bmspt-h-4 bmspt-text-gray-600 bmspt-fill-current bmspt-block bmspt-pointer-events-none" />
);
