import { LOCATION_CHANGE } from 'redux-first-history';
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLeading,
} from 'redux-saga/effects';
import {
  logoutRequest,
  setWorkflows,
  startPollingWorkflows,
} from 'redux/actions/saga';
import { FULFILLED, PENDING } from 'utils/useAsyncCallback';
import {
  fetchWorkflows,
  transformWorkflows,
} from 'utils/WorkflowUtils/WorkflowUtils';

const POLLING_DELAY = 10000;

function* poll() {
  yield put(setWorkflows({ status: PENDING, response: [] }));

  while (true) {
    try {
      const {
        authState: { token },
        selectedProjectId,
      } = yield select();

      const response = yield call(fetchWorkflows, token, selectedProjectId);

      yield put(
        setWorkflows({
          status: FULFILLED,
          response: response.workflows.map(transformWorkflows),
        })
      );
    } catch ({ code, message }) {
      const isJWTExpired = code === 401 && message.includes('jwt');

      if (isJWTExpired) {
        yield put(logoutRequest());
      }

      yield put(setWorkflows({ status: { code, message } }));
    }
    yield delay(POLLING_DELAY);
  }
}

function* workflowsWorker() {
  yield race([call(poll), take(LOCATION_CHANGE)]);
}

export function* workflowsFlow() {
  yield takeLeading(startPollingWorkflows, workflowsWorker);
}
