import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  loginError,
  loginSuccess,
  loginWithCredentials,
  loginWithToken,
  logoutRequest,
  modifyAuthState,
  twoFaPrompt,
  twoFaRequest,
} from 'redux/actions/saga';
import { INITIAL, PENDING } from 'utils/useAsyncCallback';
import {
  authUserStep1,
  authUserStep2,
  logUserOut,
} from 'utils/AuthUtils/AuthUtils';
import jwtDecode from 'jwt-decode';
import { fetchInitialData } from 'redux/sagas/authFlow/fetchInitialData';
import {
  clearUploads,
  setPermissions,
  setSelectedProjectId,
} from 'redux/actions/creators';
import { Mixpanel } from 'utils/MixpanelUtils/MixpanelActions';

export function* authFlow(credentials) {
  try {
    yield put(modifyAuthState({ status: PENDING }));

    // Step 1
    // Send credentials to /authenticate
    const { token } = yield call(authUserStep1, credentials);

    // Parse the token
    const parsed = jwtDecode(token);

    // Determine if there's a 2FA challenge
    const has2FA =
      parsed.data.roles?.length === 1 && parsed.data.roles[0] === '2FA';

    // With no 2FA challenge, save token and log the user in
    if (!has2FA) {
      return yield put(loginSuccess(token));
    }

    yield put(twoFaPrompt());
    const { payload: verificationCode } = yield take(twoFaRequest);
    const { token: finalToken } = yield call(
      authUserStep2,
      token,
      verificationCode
    );
    yield put(loginSuccess(finalToken));
  } catch (error) {
    yield put(loginError({ message: error.message, code: error.code }));
  }
}

export function* logout() {
  const token = yield select(({ authState: { token } }) => token);

  yield put(clearUploads());

  localStorage.removeItem('selectedProjectId');
  yield put(setSelectedProjectId(''));

  yield put(setPermissions(INITIAL, {}));

  localStorage.removeItem('authToken');
  localStorage.removeItem('selectedUseCaseId');

  yield put(
    modifyAuthState({ token: null, status: INITIAL, currentStep: 'LOGIN' })
  );

  try {
    Mixpanel.logOut();
    yield call(logUserOut, token);
  } catch {}
}

export function* authorisationFlow() {
  let authoriseTask, loginAction;
  while (true) {
    loginAction = yield take([loginWithCredentials, loginWithToken]);
    const fetchDataTask = yield takeLatest(loginSuccess, fetchInitialData);
    if (loginAction.type === loginWithCredentials.toString()) {
      authoriseTask = yield fork(authFlow, loginAction.payload);
    } else {
      yield put(loginSuccess(loginAction.payload));
    }
    const action = yield take([logoutRequest, loginError]);
    yield cancel(fetchDataTask);
    if (authoriseTask) yield cancel(authoriseTask);
    if (action.type === logoutRequest.toString()) {
      yield call(logout);
    }
  }
}
