import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProjectHome = ({ selectedProjectId }) => (
  <Redirect to={`/project/${selectedProjectId}/project-cockpit`} />
);

const mapStateToProps = ({ selectedProjectId }) => ({ selectedProjectId });

export default connect(mapStateToProps)(ProjectHome);
