import { setPermissions } from 'redux/actions/creators';
import { fetchPermissions } from 'utils/PermissionUtils/permissionUtils';
import { fetchAndSaveSagaWrapper } from './utils';

const transformFn = apiResult =>
  apiResult.reduce((accumulator, { id, userGroups }) => {
    accumulator[id] = userGroups[0];
    return accumulator;
  }, {});

export const fetchAndSavePermissions = fetchAndSaveSagaWrapper({
  setter: setPermissions,
  fetchFn: fetchPermissions,
  transformApiResult: transformFn,
});
