import React from 'react';
import Modal from 'react-bootstrap/Modal';

import ModalCloseButton from 'utils/ModalUtils/ModalCloseButton';
import { ReactComponent as ACLBlock } from 'assets/images/acl-block-modal.svg';
import { UpgradeButton } from './FullPageBlock';

const FeatureBlockModal = ({
  dismiss,
  illustration: Illustration = ACLBlock,
  title = 'You don’t have access to this feature',
  description = 'Upgrade to have full access or contact the project’s owner',
  pageName = 'Feature block modal',
}) => (
  <>
    <Modal.Header className="bmspt-mb-0 bmspt-p-0 upload-modal__header">
      <Illustration className="bmspt-w-full bmspt-pointer-events-none" />
      <ModalCloseButton onClick={dismiss} />
    </Modal.Header>
    <Modal.Body className="bmspt-p-6">
      {title && (
        <h1 className="bmspt-mb-2 bmspt-font-bold bmspt-text-2xl">{title}</h1>
      )}
      {description && <p className="bmspt-mb-4 bmspt-text-xs">{description}</p>}
      <UpgradeButton pageName={pageName} />
    </Modal.Body>
  </>
);

export default FeatureBlockModal;
