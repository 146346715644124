import React from 'react';
import { ReactComponent as ErrorCover } from 'assets/images/empty-states/void.svg';

export const ErrorScreen = () => (
  <div className="padded-container bmspt-flex bmspt-items-center bmspt-justify-center">
    <div className="bmspt-flex bmspt-flex-col bmspt-h-full bmspt-justify-center bmspt-items-center">
      <ErrorCover className="bmspt-max-w-full bmspt-max-h-full" />
      <h1 className="bmspt-mt-6 bmspt-text-center bmspt-text-4xl bmspt-font-bold">
        Oops...
      </h1>
      <p className="bmspt-px-12 bmspt-font-normal bmspt-text-center">
        It seems that something went wrong. Please try to load the page again.
      </p>
    </div>
  </div>
);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
