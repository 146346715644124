import * as yup from 'yup';

const userGroup = yup.object().shape({
  name: yup.string().required(),
  users: yup
    .array()
    .of(yup.string().required())
    .ensure(),
});

const organisationSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
  stripeId: yup.string().ensure(),
  postcode: yup.string().ensure(),
  city: yup.string().ensure(),
  address1: yup.string().ensure(),
  address2: yup.string().ensure(),
  country: yup.string().ensure(),
  phoneNumber: yup.string().ensure(),
  vat: yup.string().ensure(),
  suspended: yup.boolean().required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  userGroups: yup.array().of(userGroup),
  companySize: yup.string().ensure(),
  industry: yup.string().ensure(),
  useCase: yup.string().ensure(),
});

export const organisationListSchema = yup.array().of(organisationSchema);

const countrySchema = yup.object().shape({
  code: yup.string().required(),
  name: yup.string().required(),
});

export const countriesSchema = yup.array().of(countrySchema);
