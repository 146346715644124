import { getJSONWithToken, patchJSONWithToken } from '../utils';
import { userSchema } from './UserSchema';

const FETCH_USER_DETAILS_ERROR = 'Could not fetch user details.';
const UPDATE_USER_DETAILS_ERROR = 'Could not update user details.';

export const fetchUserDetails = (token, userId) =>
  getJSONWithToken(
    `user/${userId}`,
    token,
    FETCH_USER_DETAILS_ERROR,
    userSchema
  );

export const updateUserDetails = (token, userId, payload) =>
  patchJSONWithToken(
    `user/${userId}`,
    token,
    payload,
    UPDATE_USER_DETAILS_ERROR
  );

export const userRoleMapping = {
  ARC: 'Architect',
  STR: 'Structural Engineer',
  MEP: 'MEP Engineer',
  PM: 'Project Manager',
};
