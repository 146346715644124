import { setUseCases } from 'redux/actions/creators';
import { fetchUseCases } from 'utils/UseCaseUtils/UseCaseUtils';
import { fetchAndSaveSagaWrapper } from './utils';

export const fetchAndSaveUseCases = fetchAndSaveSagaWrapper({
  selector: ({ authState: { token }, selectedProjectId }) => [
    token,
    selectedProjectId,
  ],
  setter: setUseCases,
  fetchFn: fetchUseCases,
  initialState: [],
});
